import AgoraRTC from "agora-rtc-sdk-ng";
import "../../styles/sa-video-call.scss";
import { changeDeviceInput } from "../helper/changeDeviceInput";
import { deviceSettings } from "../helper/deviceSettings";
import { flipCamera } from "../helper/flipCamera";
import { getDevices } from "../helper/getDevices";
import { enableVirtualBackground } from "../helper/enableVirtualBackground";
import { joinChannel } from "../helper/joinChannel";
import { leaveChannel } from "../helper/leaveChannel";
import { moreOptions } from "../helper/moreOptions";
import { pipModeHandler } from "../helper/pipModeHandler";
import { recordCall } from "../helper/recordCall";
import { screenShare } from "../helper/screenShare";
import { toggleAudio } from "../helper/toggleAudio";
import { toggleCamera } from "../helper/toggleCamera";
import { virtualBackground } from "../helper/virtualBackground";
import { virtualBackgroundHandler } from "../helper/virtualBackgroundHandler";
import {
  chatBox,
  onContentUpload,
  sendMessage,
  receiveMessage,
  onScreenShotUpload,
} from "../helper/chatBox";
import { checkUnreadMessages } from "../helper/checkUnreadMessages";
import { onHoldCall } from "../helper/onHoldCall";
import { startLivestream, stopLivestream } from "../helper/livestreamHandler";
import { getEmailUsernameOrNothing } from "../helper/others";
import { bottomAlert } from "../../../utils/toastAlerts";
import { config } from "../../../config";
import { io } from "socket.io-client";
import { networkQuality } from "../helper/networkQuality";
// import { config } from "../../../config";

// Set the log level => {0: DEBUG, 1: INFO, 2: WARNING, 3: ERROR, 4: NONE}
AgoraRTC.setLogLevel(2);

class VideoCall {
  constructor(
    key,
    message_type,
    meeting_data,
    end_callback,
    UserContext,
    ClientContext,
    SocketContext,
    setSocketContext
  ) {
    this.agoraAppId = key.widget_creds.app_id;
    this.agoraChannelName = key.widget_creds.channel_name;
    this.agoraToken = key.widget_creds.token;
    this.agoraUID = key.widget_creds.uid;
    this.message_type = message_type;
    this.meeting_data = meeting_data;
    this.end_callback = end_callback;
    this.user_display_name = key.user_display_name;
    this.UserContext = UserContext;
    this.ClientContext = ClientContext;

    this.client = AgoraRTC.createClient({
      mode:
        this.meeting_data.meeting_type === "live_streaming" ? "live" : "rtc",
      codec: "vp8",
    });
    this.localAudioTrack = null;
    this.localVideoTrack = null;
    this.localScreenTrack = null;
    this.remoteUsers = {};
    this.localAudioTrackMuted = false;
    this.localVideoTrackMuted = false;
    this.isSharingEnabled = false;
    this.videoInputDevices = [];
    this.audioInputDevices = [];
    this.isRecording = key.is_recording_on;
    this.files = [];
    this.isMessageOpen = false;
    this.loaderEl = "";
    this.resizeObserver = null;

    this.denoiser = null;
    this.processor = null;

    console.log(`${this.message_type} Initialised`);

    // var socket = io(config.url.SOCKET_URL, {
    //   query: `meeting_id=${this.meeting_data.id}&id=${this.UserContext.id}&client_id=${this.ClientContext.id}&type=agent&uid=${this.UserContext.email}`,
    // });

    // window.socket = socket;

    // socket.on("connect", (data) => {
    //   console.log(data, "socket connected successfully");
    //   this.socket = socket;
    // });

    this.socket = SocketContext.socket;
    this.setSocketContext = setSocketContext;

    // If 'this.socket' is undefined or null, it will attempt to initialize it again.
    if (!this.socket) {
      const socket = io(config.url.SOCKET_URL, {
        query: `id=${this.UserContext.id}&type=agent&uid=${this.UserContext.email}&client_id=${this.ClientContext.id}`,
      });

      socket.on("connect", (data) => {
        console.log(data, "socket connected successfully");
        this.socket = socket;
        setSocketContext({ socket: socket });
      });
    }

    this.initSocket();

    this.init().then(() => this.renderElement());
  }

  initSocket() {
    if (this.socket) {
      this.socket.emit("meeting_joined", {
        meeting_id: this.meeting_data.id,
        uid: this.UserContext.email,
      });

      this.setSocketContext((prev) => ({ ...prev, agoraClient: this.client }));

      this.socket.on("message", async (data) => {
        // console.log("Message from socket", data);

        if (data.message === "people_connected") {
          bottomAlert("User joined the call", "success");
        }

        if (data.message === "people_disconnected") {
          bottomAlert("User left the call", "error");
        }
      });

      this.socket.on("chat_message", async (data) => {
        // console.log("Chat message from socket", data);

        for (const key in data) {
          if (key === "text" && this.UserContext.email !== data.userName) {
            receiveMessage(getEmailUsernameOrNothing(data.userName), data.text);
          }

          if (key === "networkQuality" && this.agoraUID !== data.uid) {
            const networkQualityIndicator = document.querySelector(
              `#remoteVideo_${data.uid} .network-quality-info`
            );

            if (networkQualityIndicator) {
              networkQualityIndicator.style.color = data.color;
            }
          }
        }
      });
    }
  }

  async init() {
    this.loaderEl = document.querySelector(".saleassist-video-call-loader");

    if (this.meeting_data.meeting_type === "live_streaming") {
      this.client.setClientRole("host");
    }

    this.loaderEl.style.display = "flex";

    await getDevices.call(this, AgoraRTC);
    await joinChannel.call(this, AgoraRTC);

    this.loaderEl.style.display = "none";

    console.log("video", await this.videoInputDevices);
    console.log("audio", await this.audioInputDevices);
  }

  async renderElement() {
    const endMeetingForMe = document.getElementById("end-meeting-for-me");
    const endMeetingForEveryone = document.getElementById(
      "end-meeting-for-everyone"
    );
    const screenShareEl = document.getElementById("screenShare");
    const flipCameraEl = document.getElementById("flipCamera");
    const toggleCameraEl = document.getElementById("toggleCamera");
    const toggleAudioEl = document.getElementById("toggleAudio");
    const recordButtonEl = document.getElementById("recordButton");
    const moreButtonEl = document.getElementById("moreButton");
    const settingsButtonEl = document.getElementById("sa-settings");
    const inputDeviceSettingsCloseEl = document.getElementById(
      "inputDeviceSettingsClose"
    );
    const videoInputDeviceSettingSelectEl = document.querySelector(
      "#videoInputDeviceSetting select"
    );
    const audioInputDeviceSettingSelectEl = document.querySelector(
      "#audioInputDeviceSetting select"
    );
    const pipModeButtonEl = document.getElementById("sa-pip-mode");
    const virtualBackgroundEl = document.getElementById(
      "sa-virtual-background"
    );
    const virtualBackgroundWindowCloseEl = document.getElementById(
      "virtualBackgroundWindowClose"
    );
    const virtualBackgroundSliderEl = document.getElementById(
      "virtualBackgroundSlider"
    );
    const virtualBackgroundWindowTabsBtnEl = document.querySelectorAll(
      ".virtual-background-window-tabs .tab-text-Button"
    );
    const messageButtonEl = document.getElementById("sa-message");
    const ChatMessageInputEl = document.getElementById(
      "saleassistVideoChatMessageInput"
    );
    const ChatSendButtonEl = document.getElementById(
      "saleassistVideoChatSendButton"
    );
    const ChatCloseButtonEl = document.getElementById(
      "saleassistVideoChatCloseButton"
    );
    const ChatUploadButton = document.querySelector(
      "#saleassistVideoChatUploadButton #upload-file"
    );
    const ChatLogEl = document.querySelector(".saleassist-video-call-chat-log");
    const holdCallButtonEl = document.querySelector("#sa-hold-call");
    const ChatScreenshotButton = document.querySelector(
      "#saleassistVideoChatScreenshotButton"
    );
    const liveStreamLiveButtonEl = document.querySelector(
      ".sa-live-stream-live-button"
    );

    // get the network quality of the local user;
    networkQuality.call(this);

    // screen share support for browser is being checked
    if (
      "mediaDevices" in navigator &&
      "getDisplayMedia" in navigator.mediaDevices
    ) {
      screenShareEl.style.display = "flex";
    }

    // hiding the camera toggle button for audio call
    if (this.message_type === "audio_call") {
      toggleCameraEl.style.display = "none";
      document.getElementById("videoInputDeviceSetting").style.display = "none";
    }

    // start the recording if auto record is on.
    if (this.meeting_data.auto_record) {
      this.isRecording = true;
      recordButtonEl.style.color = "#ff0000";
    }

    // change the color when the recording is on.
    if (this.isRecording) {
      recordButtonEl.style.color = "#ff0000";
    }

    // await joinChannel.call(this, AgoraRTC);

    endMeetingForMe.addEventListener("click", async () => {
      await leaveChannel.call(this, endMeetingForMe);
    });

    endMeetingForEveryone.addEventListener("click", async () => {
      await leaveChannel.call(this, endMeetingForEveryone, true);
    });

    screenShareEl.addEventListener("click", async () => {
      await screenShare.call(this, AgoraRTC, screenShareEl);
    });

    flipCameraEl.addEventListener("click", async () => {
      await flipCamera.call(this, videoInputDeviceSettingSelectEl);
    });

    toggleCameraEl.addEventListener("click", async () => {
      await toggleCamera.call(this, toggleCameraEl, AgoraRTC);
    });

    toggleAudioEl.addEventListener("click", async () => {
      await toggleAudio.call(this, toggleAudioEl, AgoraRTC);
    });

    recordButtonEl.addEventListener("click", async () => {
      await recordCall.call(this, recordButtonEl);
    });

    moreButtonEl.addEventListener("click", () => {
      moreOptions();
    });

    settingsButtonEl.addEventListener("click", () => {
      deviceSettings("open");
    });

    inputDeviceSettingsCloseEl.addEventListener("click", () => {
      deviceSettings("close");
    });

    if (this.message_type === "video_call" && videoInputDeviceSettingSelectEl) {
      videoInputDeviceSettingSelectEl.addEventListener("change", async (e) => {
        await changeDeviceInput.call(this, "video", e.target.value);
      });
    }

    if (audioInputDeviceSettingSelectEl) {
      audioInputDeviceSettingSelectEl.addEventListener("change", async (e) => {
        await changeDeviceInput.call(this, "audio", e.target.value);
      });
    }

    pipModeButtonEl.addEventListener("click", () => {
      pipModeHandler();
    });

    virtualBackgroundEl.addEventListener("click", () => {
      virtualBackground("open");
    });

    virtualBackgroundWindowCloseEl.addEventListener("click", () => {
      virtualBackground("close");
    });

    virtualBackgroundSliderEl.addEventListener("change", async () => {
      await enableVirtualBackground.call(
        this,
        AgoraRTC,
        virtualBackgroundSliderEl
      );
    });

    // adding blur preview on first render
    await virtualBackgroundHandler.call(
      this,
      virtualBackgroundWindowTabsBtnEl[0]
    );

    virtualBackgroundWindowTabsBtnEl.forEach((tab) => {
      tab.addEventListener("click", async () => {
        await virtualBackgroundHandler.call(this, tab);
      });
    });

    if (this.ClientContext.is_visible) {
      virtualBackgroundSliderEl.click();
      await virtualBackgroundHandler.call(
        this,
        virtualBackgroundWindowTabsBtnEl[2]
      );
    }

    messageButtonEl.addEventListener("click", () => {
      if (this.isMessageOpen) {
        chatBox("close");
      } else {
        chatBox("open");
      }
      this.isMessageOpen = !this.isMessageOpen;
    });

    ChatCloseButtonEl.addEventListener("click", () => {
      chatBox("close");
      this.isMessageOpen = false;
    });

    ChatSendButtonEl.addEventListener("click", () => {
      sendMessage.call(this);
    });

    ChatMessageInputEl.addEventListener("keydown", (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        sendMessage.call(this);
      }
    });

    ChatUploadButton.addEventListener("click", (e) => {
      e.target.value = null;
    });

    ChatUploadButton.addEventListener("change", (e) => {
      onContentUpload.call(this, e);
    });

    ChatLogEl.addEventListener("DOMSubtreeModified", checkUnreadMessages);

    holdCallButtonEl.addEventListener("click", () => {
      onHoldCall.call(this, holdCallButtonEl);
    });

    ChatScreenshotButton.addEventListener("click", () => {
      onScreenShotUpload.call(this);
    });

    if (this.meeting_data.meeting_type === "live_streaming") {
      stopLivestream.call(this);

      liveStreamLiveButtonEl.addEventListener("click", () => {
        if (liveStreamLiveButtonEl.innerText === "Go Live") {
          startLivestream.call(this, liveStreamLiveButtonEl);
        } else {
          stopLivestream.call(this, liveStreamLiveButtonEl);
        }
      });

      // for live stream adding logic to adapt layout based on available width
      const targetElement = document.querySelector("#videoCallMainScreen");

      this.resizeObserver = new ResizeObserver((entries) => {
        let width;
        for (let entry of entries) {
          // console.log("New width:", entry.contentRect.width);
          // console.log("New height:", entry.contentRect.height);
          width = entry.contentRect.width;
        }

        if (window.innerWidth > 768 && width < 810) {
          targetElement.classList.add("sa-optimize--layout");
        } else {
          targetElement.classList.remove("sa-optimize--layout");
        }
      });

      this.resizeObserver.observe(targetElement);
    }
  }
}

export default VideoCall;
