import React from "react";

function DeviceAccessError({ error_type }) {
  let default_message = error_type.split("~");
  let headerText = default_message[0] || "";
  let bodyText = default_message[1] || "";

  if (error_type === "NotAllowedError") {
    headerText = "Your camera and microphone are blocked";
    bodyText = `Meet requires access to your camera and microphone. Click the camera blocked icon <img alt="" aria-hidden="true" style="width:1.5rem;height:1.5rem;vertical-align:middle" src="//www.gstatic.com/meet/ic_blocked_camera_7ca83311f629f64699401950ceaed61e.svg">  in your browser's address bar and then refresh.`;
  }

  if (error_type === "NotReadableError") {
    headerText = "Something is worng with your camera or microphone";
    bodyText =
      "Meet is unable to access your camera or microphone, but you will be still able to join the call.";
  }

  return (
    <div className="sa-device-access-error-wrapper">
      <div className="sa-device-access-error-cont">
        <div className="sa-device-access-error-header">{headerText}</div>
        <div
          className="sa-device-access-error-body"
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
        <div className="sa-device-access-error-footer">
          <div className="sa-device-access-error-dismiss-btn">Dismiss</div>
        </div>
      </div>
    </div>
  );
}

export default DeviceAccessError;
