export const pipModeHandler = () => {
  const remoteUserVideoEl = document.querySelector(
    "#videoCallVideoTiles video"
  );
  const localUserVideoEl = document.querySelector("#localVideoContainer video");
  const mainScreenUserVideoEl = document.querySelector(
    "#videoCallMainScreen video"
  );
  const moreOptionsControlContEl = document.querySelector(
    ".saleassist-video-call-more-options-control"
  );

  if (document.pictureInPictureElement) {
    document.exitPictureInPicture();
  } else if (document.pictureInPictureEnabled) {
    if (remoteUserVideoEl) {
      remoteUserVideoEl.requestPictureInPicture().catch((error) => {
        console.log("Error entering Picture-in-Picture mode:", error);
      });
    } else if (localUserVideoEl) {
      localUserVideoEl.requestPictureInPicture().catch((error) => {
        console.log("Error entering Picture-in-Picture mode:", error);
      });
    } else {
      mainScreenUserVideoEl.requestPictureInPicture().catch((error) => {
        console.log("Error entering Picture-in-Picture mode:", error);
      });
    }
  }

  moreOptionsControlContEl.classList.remove("more-options-control-active");
};
