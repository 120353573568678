import React, { useState, useEffect } from "react";

function countdown(targetTimeString) {
  // Get the current time
  const currentTime = new Date();

  // Parse the target time string
  const [hours, minutes, period] = targetTimeString.split(/[:\s]+/);

  // Convert hours to 24-hour format if necessary
  const targetHours =
    period.toUpperCase() === "PM"
      ? parseInt(hours, 10) + 12
      : parseInt(hours, 10);

  // Set the target time using the parsed values
  const targetTime = new Date();
  targetTime.setHours(targetHours, parseInt(minutes, 10), 0, 0);

  // Calculate the time difference in milliseconds
  let timeDifference = targetTime.getTime() - currentTime.getTime();

  // Check if the target time is in the future
  if (timeDifference < 0) {
    return "Time has already passed!";
  }

  // Calculate hours and minutes
  const hoursRemaining = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutesRemaining = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  // Return the countdown result
  return {
    hours: hoursRemaining,
    minutes: minutesRemaining,
  };
}

export default function CountdownTimer({ targetTime, customText }) {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remaining = countdown(targetTime);
      setTimeRemaining(remaining);

      if (remaining === "Time has already passed!") {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [targetTime]);

  return (
    <>
      {timeRemaining !== null ? (
        <span>
          {customText ? customText : "Countdown : "}
          {timeRemaining.hours} hrs and {timeRemaining.minutes} mins.
        </span>
      ) : (
        <span>Calculating countdown...</span>
      )}
    </>
  );
}
