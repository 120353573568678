import React from "react";
import ReactDOM from "react-dom";
import ScreenShareOffButton from "../../components/Buttons/screen-share-off";
import ScreenShareOnButton from "../../components/Buttons/screen-share-on";

export async function screenShare(AgoraRTC, e) {
  let screenVideoTrack = null;
  let screenAudioTrack = null;

  try {
    const localUserPlaceholderEl = document.getElementById(
      "localUserPlaceholder"
    );

    if (this.isSharingEnabled === false) {
      // Create a screen sharing track
      this.localScreenTrack = await AgoraRTC.createScreenVideoTrack({}, "auto");

      if (this.localScreenTrack instanceof Array) {
        screenVideoTrack = this.localScreenTrack[0];
        screenAudioTrack = this.localScreenTrack[1];
      } else {
        screenVideoTrack = this.localScreenTrack;
      }

      if (this.message_type === "video_call") {
        if(this.localVideoTrack) {
          await this.client.unpublish(this.localVideoTrack);
          this.localVideoTrack.stop();
        }
      }

      localUserPlaceholderEl.style.display = "none"; // local user's placeholder set to none

      // Publish the screen sharing track to the channel
      screenVideoTrack.play("localVideoContainer");

      // to track screen-share ended or not
      screenVideoTrack.on("track-ended", async () => {
        // Unpublish the screen sharing track
        if (screenAudioTrack == null) {
          await this.client.unpublish(screenVideoTrack);
          screenVideoTrack.close();
        } else {
          await this.client.unpublish([screenVideoTrack, screenAudioTrack]);
          screenVideoTrack.close();
          screenAudioTrack.close();
        }

        if (this.message_type === "video_call") {
          if(this.localVideoTrack) {
            this.localVideoTrack.play("localVideoContainer");
            await this.client.publish(this.localVideoTrack);
          }
        }

        // local user's placeholder set to visible based on camera on/off
        if (this.localVideoTrack && this.localVideoTrack.getMediaStreamTrack().enabled) {
          localUserPlaceholderEl.style.display = "none";
        } else {
          localUserPlaceholderEl.style.display = "flex";
        }

        // e.innerHTML = screenShareOnButton();
        ReactDOM.render(<ScreenShareOnButton />, e);
        this.isSharingEnabled = false;
      });

      if (screenAudioTrack === null) {
        if(screenVideoTrack) {
          await this.client.publish(screenVideoTrack);
        }
      } else {
        if(screenVideoTrack && screenAudioTrack) {
          await this.client.publish([screenVideoTrack, screenAudioTrack]);
        }
      }

      // e.innerHTML = <ScreenShareOffButton/>;
      ReactDOM.render(<ScreenShareOffButton />, e);
      this.isSharingEnabled = true;
    } else {
      if (this.localScreenTrack instanceof Array) {
        screenVideoTrack = this.localScreenTrack[0];
        screenAudioTrack = this.localScreenTrack[1];
      } else {
        screenVideoTrack = this.localScreenTrack;
      }

      // Unpublish the screen sharing track
      if (screenAudioTrack == null) {
        await this.client.unpublish(screenVideoTrack);
        screenVideoTrack.close();
      } else {
        await this.client.unpublish([screenVideoTrack, screenAudioTrack]);
        screenVideoTrack.close();
        screenAudioTrack.close();
      }

      if (this.message_type === "video_call") {
        if(this.localVideoTrack) {
          this.localVideoTrack.play("localVideoContainer");
          await this.client.publish(this.localVideoTrack);
        }
      }

      // local user's placeholder set to visible based on camera on/off
      if (this.localVideoTrack && this.localVideoTrack.getMediaStreamTrack().enabled) {
        localUserPlaceholderEl.style.display = "none";
      } else {
        localUserPlaceholderEl.style.display = "flex";
      }

      // e.innerHTML = <ScreenShareOnButton/>;
      ReactDOM.render(<ScreenShareOnButton />, e);
      this.isSharingEnabled = false;
    }
  } catch (error) {
    console.error("Screen sharing failed:", error);
  }
}
